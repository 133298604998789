// Landing Page Data
export const landingPageData = {
  coming_soon: "Avant l’ouverture du Site de réservation !",
  heading: "The Place to Be en Vendée",
  notify: {
    text: "Journées Etudes - Réunions  - Presse  - Workshop - Cocktails et Soirées",
    text2: "Pour être prévenu de l’ouverture et bénéficier d’un accès privilégié : laissez nous votre mail !",
    button: "Prévenez-moi",
    placeholder: "Entrez votre adresse mail...",
  },
};

// Social Media List
export const socialMediaData = [
  {
    icon: "fab fa-twitter",
    link: "http://www.twitter.com/",
    name: "Twitter",
    class: "social-icons-twitter",
  },
  {
    icon: "fab fa-facebook",
    link: "http://www.facebook.com/",
    name: "Facebook",
    class: "social-icons-facebook",
  },
  {
    icon: "fab fa-instagram",
    link: "http://www.instagram.com/",
    name: "Instagram",
    class: "social-icons-instagram",
  },
];

// About Us Data
export const aboutData = {
  desc: "Organisez vos séminaires, journées d’études, réunions de direction, présentation de produits, petits déjeuners, conférences de presse et vos Cocktails et Soirées dans un lieu atypique plein de surprises face à l’océan .",
  cards: [
    {
      icon: "far fa-thumbs-up",
      title: "EXCLUSIF !",
      desc: "Pour vos évènements privés et privilégiés, cette terrasse hors norme se privatise en totalité pour un cocktail exceptionnel ou une soirée exclusive avec une vue unique à 270° sur l’océan .",
    },
    {
      icon: "far fa-eye",
      title: "Réservations",
      desc: "Selon le type d’évènement, c’est un espace unique qui s’adapte à vos besoins et vos envies, rdv mercredi 13 septembre 2023 à partir de 9h00 pour réserver votre date !",
    },
    {
      icon: "far fa-paper-plane",
      title: "Un lieu Unique !",
      desc: "Une salle panoramique de 20 m2 prolongée d’une terrasse de 60 M2 avec une vue à 270 ° sur l’océan, ascenseur et parking privatif exclusivement dédiée à votre événement .",
    },
  ],
  projects_title: "Some of our most recent projects.",
  projects: [
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-2.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-3.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-4.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-5.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-6.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-7.jpg",
    },
  ],
  get_in_touch: "Get in touch with us",
};

// Contact Us data
export const contactData = {
  desc: "We launch our new website soon. For Customer Support and Query, Get in touch with us.",
  support_note: "For Customer Support, Send us a note.",
  cards: [
    {
      icon: "fas fa-map-marker-alt",
      title: "VISIT US",
      lines: ["145 Murphy Canyon Rd.", "Suite 100-18, San Diego CA 2028"],
    },
    {
      icon: "fas fa-phone-alt",
      title: "CALL US NOW",
      lines: ["Phone: (+060) 9898980098", "Fax: (+060) 8898880088"],
    },
    {
      icon: "fas fa-envelope",
      title: "INQUIRIES",
      lines: ["info@zooninc.com", "hr@zooninc.com"],
    },
    {
      icon: "fas fa-clock",
      title: "BUSINESS HOURS",
      lines: ["Mon to Fri", "(10 am – 8 pm)"],
    },
  ],

  form: {
    nameLable: "What is Your Name:",
    namePlaceholder: "Enter Your Name",

    emailLable: "Your Email Address:",
    emailPlaceholder: "Enter Your Email",

    messageLable: "How can I Help you?",
    messagePlaceholder: "Enter Your Query",

    submitButton: "Send Message",
  },
  contact: "(+91) 11-2142-566",
};
