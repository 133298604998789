import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider1 from "./templates/slider/Slider1";
import { ToastContainer } from "react-toastify";

function App() {
  const applyTheme = () => {
    return <Slider1></Slider1>;
  };

  return (
    <>
      {applyTheme()} <ToastContainer />
    </>
  );
}

export default App;
